const video = document.querySelector('video');

const whichSizeVideo = (element) => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 768) {
    video.src = element.dataset.mobileVideo;
  } else {
    video.src = element.dataset.desktopVideo;
  }
};

function videoSize() {
  if (video) {
    whichSizeVideo(video);
  }
}

videoSize();
